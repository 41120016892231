import Box from "@mui/material/Box"
import Image from "next/image"

const ratio = 5/4; 
const HomeArticleImage = ({article}) => {
  	return (
	<Box 
		className='position-relative w-100' 
		sx={{
			minHeight: {
			md: (300 / ratio),
			xs: (125 / ratio),
			},
			position: 'relative',
			overflow: 'hidden',
		}}
	>
		<Image 
			src={article.image}
			alt={article.title}
			fill
			quality={100}
			style={{
				objectFit: 'cover'
			}}
			sizes="(max-width: 768px) 50vw, (max-width: 1200px) 25vw, 16vw"
		/>
	</Box>
	)
}

export default HomeArticleImage